.toast-container[data-v-44e95ebc] {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;
  z-index: 998;
}

.toast-container .toast-box[data-v-44e95ebc] {
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 10px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,0.32);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.32);
  background-color: rgba(0,0,0,0.91);
  border: 1px solid #999999;
  border-radius: 8px;
}

.toast-container .toast-box .toast-message[data-v-44e95ebc] {
  color: #ffffff;
}

.toast-container .toast-box .toast-button[data-v-44e95ebc] {
  font-size: 16px;
  color: #999999;
  background: inherit;
  border: 1px solid #999999;
  border-radius: 4px;
  width: 63px;
  height: 32px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.slide-fade-enter-active[data-v-44e95ebc] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slide-fade-leave-active[data-v-44e95ebc] {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.slide-fade-enter[data-v-44e95ebc],.slide-fade-leave-to[data-v-44e95ebc] {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.divider[data-v-19c4239c],.divider-light[data-v-19c4239c] {
  border-bottom: 2px solid #eee;
  margin-bottom: 30px;
}

.divider[data-v-19c4239c]::before,.divider-light[data-v-19c4239c]::before {
  display: block;
  content: '';
  height: 30px;
}

.divider-light[data-v-19c4239c] {
  margin: 0px;
  border-bottom: 1px solid #f0f0f0;
}

.divider-light[data-v-19c4239c]::before {
  height: 0px;
}

.text-middle p[data-v-19c4239c] {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #5a5a5a;
}

.text-middle p.text-title[data-v-19c4239c] {
  overflow: hidden;
  margin-bottom: 15px;
}

.text-middle p.text-title[data-v-19c4239c]:before,.text-middle p.text-title[data-v-19c4239c]:after {
  background-color: #efefef;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.text-middle p.text-title[data-v-19c4239c]:before {
  right: 0.5em;
  margin-left: -50%;
}

.text-middle p.text-title[data-v-19c4239c]:after {
  left: 0.5em;
  margin-right: -50%;
}

[data-v-42d2550c]:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*[data-v-42d2550c],*[data-v-42d2550c]::before,*[data-v-42d2550c]::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html[data-v-42d2550c] {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article[data-v-42d2550c],figcaption[data-v-42d2550c],figure[data-v-42d2550c],footer[data-v-42d2550c],header[data-v-42d2550c],main[data-v-42d2550c],nav[data-v-42d2550c],section[data-v-42d2550c] {
  display: block;
}

body[data-v-42d2550c] {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"][data-v-42d2550c]:focus {
  outline: 0 !important;
}

hr[data-v-42d2550c] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1[data-v-42d2550c],h2[data-v-42d2550c],h3[data-v-42d2550c],h4[data-v-42d2550c],h5[data-v-42d2550c],h6[data-v-42d2550c] {
  margin-top: 0;
  margin-bottom: .5rem;
}

p[data-v-42d2550c] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol[data-v-42d2550c],ul[data-v-42d2550c],dl[data-v-42d2550c] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol[data-v-42d2550c],ul ul[data-v-42d2550c],ol ul[data-v-42d2550c],ul ol[data-v-42d2550c] {
  margin-bottom: 0;
}

dt[data-v-42d2550c] {
  font-weight: 700;
}

dd[data-v-42d2550c] {
  margin-bottom: .5rem;
  margin-left: 0;
}

b[data-v-42d2550c],strong[data-v-42d2550c] {
  font-weight: bolder;
}

small[data-v-42d2550c] {
  font-size: 80%;
}

sub[data-v-42d2550c] {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub[data-v-42d2550c] {
  bottom: -.25em;
}

a[data-v-42d2550c] {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a[data-v-42d2550c]:hover {
  color: #0056b3;
  text-decoration: underline;
}

a[data-v-42d2550c]:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a[data-v-42d2550c]:not([href]):not([tabindex]):hover,a[data-v-42d2550c]:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a[data-v-42d2550c]:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre[data-v-42d2550c],code[data-v-42d2550c] {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre[data-v-42d2550c] {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure[data-v-42d2550c] {
  margin: 0 0 1rem;
}

img[data-v-42d2550c] {
  vertical-align: middle;
  border-style: none;
}

svg[data-v-42d2550c] {
  overflow: hidden;
  vertical-align: middle;
}

table[data-v-42d2550c] {
  border-collapse: collapse;
}

caption[data-v-42d2550c] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th[data-v-42d2550c] {
  text-align: inherit;
}

label[data-v-42d2550c] {
  display: inline-block;
  margin-bottom: .5rem;
}

button[data-v-42d2550c] {
  border-radius: 0;
}

button[data-v-42d2550c]:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input[data-v-42d2550c],button[data-v-42d2550c],select[data-v-42d2550c],textarea[data-v-42d2550c] {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button[data-v-42d2550c],input[data-v-42d2550c] {
  overflow: visible;
}

button[data-v-42d2550c],select[data-v-42d2550c] {
  text-transform: none;
}

button[data-v-42d2550c],html [type="button"][data-v-42d2550c],[type="reset"][data-v-42d2550c],[type="submit"][data-v-42d2550c] {
  -webkit-appearance: button;
}

button[data-v-42d2550c]::-moz-focus-inner,[type="button"][data-v-42d2550c]::-moz-focus-inner,[type="reset"][data-v-42d2550c]::-moz-focus-inner,[type="submit"][data-v-42d2550c]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"][data-v-42d2550c],input[type="checkbox"][data-v-42d2550c] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"][data-v-42d2550c],input[type="time"][data-v-42d2550c],input[type="datetime-local"][data-v-42d2550c],input[type="month"][data-v-42d2550c] {
  -webkit-appearance: listbox;
}

textarea[data-v-42d2550c] {
  overflow: auto;
  resize: vertical;
}

progress[data-v-42d2550c] {
  vertical-align: baseline;
}

[type="number"][data-v-42d2550c]::-webkit-inner-spin-button,[type="number"][data-v-42d2550c]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"][data-v-42d2550c] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"][data-v-42d2550c]::-webkit-search-cancel-button,[type="search"][data-v-42d2550c]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[data-v-42d2550c]::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary[data-v-42d2550c] {
  display: list-item;
  cursor: pointer;
}

template[data-v-42d2550c] {
  display: none;
}

[hidden][data-v-42d2550c] {
  display: none !important;
}

.form-control[data-v-42d2550c] {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control[data-v-42d2550c] {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[data-v-42d2550c]::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[data-v-42d2550c]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.form-control[data-v-42d2550c]::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-42d2550c]::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-42d2550c]:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-42d2550c]::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-42d2550c]::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-42d2550c]:disabled,.form-control[readonly][data-v-42d2550c] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control[data-v-42d2550c]:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-range[data-v-42d2550c] {
  display: block;
  width: 100%;
}

.col-form-label[data-v-42d2550c] {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg[data-v-42d2550c] {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm[data-v-42d2550c] {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-sm[data-v-42d2550c] {
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg[data-v-42d2550c] {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size][data-v-42d2550c],select.form-control[multiple][data-v-42d2550c] {
  height: auto;
}

textarea.form-control[data-v-42d2550c] {
  height: auto;
}

.form-group[data-v-42d2550c] {
  margin-bottom: 1rem;
}

.form-text[data-v-42d2550c] {
  display: block;
  margin-top: .25rem;
}

.form-row[data-v-42d2550c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col[data-v-42d2550c],.form-row>[class*="col-"][data-v-42d2550c] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check[data-v-42d2550c] {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input[data-v-42d2550c] {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label[data-v-42d2550c] {
  color: #6c757d;
}

.form-check-label[data-v-42d2550c] {
  margin-bottom: 0;
}

.form-check-inline[data-v-42d2550c] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input[data-v-42d2550c] {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-control.is-valid[data-v-42d2550c],.custom-select.is-valid[data-v-42d2550c] {
  border-color: #28a745;
}

.form-control.is-valid[data-v-42d2550c]:focus,.custom-select.is-valid[data-v-42d2550c]:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-check-input.is-valid ~ .form-check-label[data-v-42d2550c] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-42d2550c] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-42d2550c]::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid:checked ~ .custom-control-label[data-v-42d2550c]::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label[data-v-42d2550c]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-control.is-invalid[data-v-42d2550c],.custom-select.is-invalid[data-v-42d2550c] {
  border-color: #dc3545;
}

.form-control.is-invalid[data-v-42d2550c]:focus,.custom-select.is-invalid[data-v-42d2550c]:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-check-input.is-invalid ~ .form-check-label[data-v-42d2550c] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-42d2550c] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-42d2550c]::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label[data-v-42d2550c]::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label[data-v-42d2550c]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-inline[data-v-42d2550c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check[data-v-42d2550c] {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label[data-v-42d2550c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group[data-v-42d2550c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control[data-v-42d2550c] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group[data-v-42d2550c],  .form-inline .custom-select[data-v-42d2550c] {
    width: auto;
  }

  .form-inline .form-check[data-v-42d2550c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input[data-v-42d2550c] {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control[data-v-42d2550c] {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label[data-v-42d2550c] {
    margin-bottom: 0;
  }
}

.btn[data-v-42d2550c] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn[data-v-42d2550c] {
    -webkit-transition: none;
    transition: none;
  }
}

.btn[data-v-42d2550c]:hover,.btn[data-v-42d2550c]:focus {
  text-decoration: none;
}

.btn[data-v-42d2550c]:focus,.btn.focus[data-v-42d2550c] {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.btn.disabled[data-v-42d2550c],.btn[data-v-42d2550c]:disabled {
  opacity: .65;
}

.btn[data-v-42d2550c]:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled[data-v-42d2550c] {
  pointer-events: none;
}

.btn-primary[data-v-42d2550c] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary[data-v-42d2550c]:focus,.btn-primary.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-primary.disabled[data-v-42d2550c],.btn-primary[data-v-42d2550c]:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-primary.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-primary.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-secondary[data-v-42d2550c] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary[data-v-42d2550c]:focus,.btn-secondary.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-secondary.disabled[data-v-42d2550c],.btn-secondary[data-v-42d2550c]:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-secondary.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-secondary.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-info[data-v-42d2550c] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info[data-v-42d2550c]:focus,.btn-info.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-info.disabled[data-v-42d2550c],.btn-info[data-v-42d2550c]:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-info.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-info.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-warning[data-v-42d2550c] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-42d2550c]:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning[data-v-42d2550c]:focus,.btn-warning.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-warning.disabled[data-v-42d2550c],.btn-warning[data-v-42d2550c]:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-warning.dropdown-toggle[data-v-42d2550c] {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-warning.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-light[data-v-42d2550c] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-42d2550c]:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light[data-v-42d2550c]:focus,.btn-light.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-light.disabled[data-v-42d2550c],.btn-light[data-v-42d2550c]:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-light.dropdown-toggle[data-v-42d2550c] {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-light.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-dark[data-v-42d2550c] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark[data-v-42d2550c]:focus,.btn-dark.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-dark.disabled[data-v-42d2550c],.btn-dark[data-v-42d2550c]:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-dark.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-dark.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-primary[data-v-42d2550c] {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-42d2550c]:focus,.btn-outline-primary.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-primary.disabled[data-v-42d2550c],.btn-outline-primary[data-v-42d2550c]:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-primary.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-primary.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-secondary[data-v-42d2550c] {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-42d2550c]:focus,.btn-outline-secondary.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-secondary.disabled[data-v-42d2550c],.btn-outline-secondary[data-v-42d2550c]:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-secondary.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-secondary.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-info[data-v-42d2550c] {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-42d2550c]:focus,.btn-outline-info.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-info.disabled[data-v-42d2550c],.btn-outline-info[data-v-42d2550c]:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-info.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-info.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-warning[data-v-42d2550c] {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-42d2550c]:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-42d2550c]:focus,.btn-outline-warning.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-warning.disabled[data-v-42d2550c],.btn-outline-warning[data-v-42d2550c]:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-warning.dropdown-toggle[data-v-42d2550c] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-warning.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-light[data-v-42d2550c] {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-42d2550c]:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-42d2550c]:focus,.btn-outline-light.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-light.disabled[data-v-42d2550c],.btn-outline-light[data-v-42d2550c]:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-light.dropdown-toggle[data-v-42d2550c] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-light.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-dark[data-v-42d2550c] {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark[data-v-42d2550c]:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-42d2550c]:focus,.btn-outline-dark.focus[data-v-42d2550c] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-dark.disabled[data-v-42d2550c],.btn-outline-dark[data-v-42d2550c]:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark[data-v-42d2550c]:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-42d2550c],.show>.btn-outline-dark.dropdown-toggle[data-v-42d2550c] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-42d2550c]:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-42d2550c]:focus,.show>.btn-outline-dark.dropdown-toggle[data-v-42d2550c]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-link[data-v-42d2550c] {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link[data-v-42d2550c]:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link[data-v-42d2550c]:focus,.btn-link.focus[data-v-42d2550c] {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link[data-v-42d2550c]:disabled,.btn-link.disabled[data-v-42d2550c] {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg[data-v-42d2550c] {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm[data-v-42d2550c] {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block[data-v-42d2550c] {
  display: block;
  width: 100%;
}

.btn-block+.btn-block[data-v-42d2550c] {
  margin-top: .5rem;
}

input[type="submit"].btn-block[data-v-42d2550c],input[type="reset"].btn-block[data-v-42d2550c],input[type="button"].btn-block[data-v-42d2550c] {
  width: 100%;
}

.list-group[data-v-42d2550c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action[data-v-42d2550c] {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-action[data-v-42d2550c]:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action[data-v-42d2550c]:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item[data-v-42d2550c] {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
}

.list-group-item[data-v-42d2550c]:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item[data-v-42d2550c]:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.list-group-item[data-v-42d2550c]:hover,.list-group-item[data-v-42d2550c]:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled[data-v-42d2550c],.list-group-item[data-v-42d2550c]:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active[data-v-42d2550c] {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-primary[data-v-42d2550c] {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-primary.list-group-item-action[data-v-42d2550c]:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary[data-v-42d2550c] {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-secondary.list-group-item-action[data-v-42d2550c]:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-info[data-v-42d2550c] {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-info.list-group-item-action[data-v-42d2550c]:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning[data-v-42d2550c] {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-warning.list-group-item-action[data-v-42d2550c]:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-light[data-v-42d2550c] {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-light.list-group-item-action[data-v-42d2550c]:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark[data-v-42d2550c] {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action[data-v-42d2550c]:hover,.list-group-item-dark.list-group-item-action[data-v-42d2550c]:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active[data-v-42d2550c] {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con-webfont.407d448.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_bol-webfont.23e3999.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: 'krungsri_condensed_med';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
}

html[data-v-42d2550c],body[data-v-42d2550c] {
  width: 100%;
  height: 100%;
}

body[data-v-42d2550c] {
  font-family: 'krungsri_condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #705f5f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767.98px) {
  body[data-v-42d2550c] {
    background-size: auto;
  }
}

body h1[data-v-42d2550c] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 30px;
}

body h2[data-v-42d2550c] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 21px;
}

body h3[data-v-42d2550c] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 20px;
}

body h4[data-v-42d2550c] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 374.98px) {
  body h2[data-v-42d2550c] {
    font-size: 18px;
  }

  body h4[data-v-42d2550c] {
    font-size: 14px;
  }
}

body b[data-v-42d2550c] {
  font-family: 'krungsri_condensed_med';
}

body p[data-v-42d2550c] {
  line-height: 1.7;
  font-weight: normal;
}

footer[data-v-42d2550c] {
  z-index: 1;
  background-color: #d2cbcc;
  color: #705f5f;
  padding-bottom: 15px;
  text-align: center;
}

footer.collapse[data-v-42d2550c] {
  opacity: 0;
  position: absolute;
  top: -1000px;
  max-height: 0;
  visibility: hidden;
}

footer .footer-link-grid[data-v-42d2550c] {
  display: grid;
  grid: auto / 50% 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 8px 40px;
}

footer .footer-link-grid .footer-links-section[data-v-42d2550c] {
  text-align: left;
}

footer .footer-link-grid .footer-links-section .bold[data-v-42d2550c] {
  color: #463534;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}

footer .footer-link-grid .footer-links-section div[data-v-42d2550c] {
  padding: 5px 0 5px 0;
}

footer .footer-link-grid .footer-links-section a[data-v-42d2550c] {
  font-size: 14px;
  color: #705f5f;
}

footer .footer-link-grid .footer-links-section a active[data-v-42d2550c] {
  text-decoration: underline;
}

@media (min-width: 1025px) {
  footer .footer-link-grid .footer-links-section a[data-v-42d2550c] {
    font-size: 13px;
  }
}

@media (min-width: 1025px) {
  footer .footer-link-grid[data-v-42d2550c] {
    grid: auto / 25% 25% 25% 25%;
    font-size: 13px;
  }
}

.mt-16[data-v-42d2550c] {
  margin-top: 16px;
  margin-bottom: 0 !important;
}

.footer-main-wrapper[data-v-1e84a2e1] {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  background-color: #fffdf2;
  padding: 0 20px;
}

.footer-main-wrapper .divider-light[data-v-1e84a2e1] {
  border-bottom: 1px solid rgba(0,0,0,0.04);
  margin: 0 0 20px;
}

.footer-main-wrapper .divider-light.line[data-v-1e84a2e1] {
  display: none;
}

.footer-main-wrapper .divider-light.isActive[data-v-1e84a2e1] {
  display: block;
}

.footer-main-wrapper .collapse-container[data-v-1e84a2e1] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 36px;
  cursor: pointer;
  padding: 24px 0;
}

.footer-main-wrapper .collapse-container .arrow[data-v-1e84a2e1] {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.footer-main-wrapper .collapse-container .arrow.isActive[data-v-1e84a2e1] {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.footer-main-wrapper .collapse-container .about-us[data-v-1e84a2e1] {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  color: rgba(0,0,0,0.8);
  padding-left: 8px;
}

.footer-main-wrapper .collapse-container .icon-wrapper[data-v-1e84a2e1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-main-wrapper[data-v-1e84a2e1] footer {
  -webkit-transition: max-height 0.4s ease;
  transition: max-height 0.4s ease;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.footer-main-wrapper[data-v-1e84a2e1] footer .footer-link-grid {
  padding-top: 0;
  padding-bottom: 0px;
}

.footer-main-wrapper.merge-journey[data-v-1e84a2e1] {
  background-color: #fffdf2;
}

.footer-main-wrapper footer[data-v-1e84a2e1] {
  background-color: #fffdf2;
}

.footer[data-v-1e84a2e1] {
  margin: 0 auto;
  max-width: 1024px;
}

.footer-bottom[data-v-1e84a2e1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 12px;
  padding-top: 20px;
}

.footer-bottom .footer-box-2 .footer-box[data-v-1e84a2e1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
}

.footer-bottom .content[data-v-1e84a2e1] {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  line-height: 1.63;
  color: #594b4a;
}

.footer-bottom .call[data-v-1e84a2e1] {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  line-height: 1.08;
  color: #594b4a;
  margin-top: 8px;
}

.footer-bottom .call a[data-v-1e84a2e1] {
  color: #6f5f5e;
}

.footer-bottom .social-icon[data-v-1e84a2e1] {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 8px;
}

.footer-bottom .social-icon img[data-v-1e84a2e1] {
  width: 36px;
  height: 36px;
}

.copyright[data-v-1e84a2e1] {
  font-size: 12px;
  width: 100%;
  padding: 24px 20px 24px;
  text-align: center;
}

.merge-journey[data-v-1e84a2e1] {
  background-color: #fffdf2;
}

.merge-journey *[data-v-1e84a2e1] {
  background-color: #fffdf2;
  color: #292828;
  font-size: 14px;
}

.merge-journey .footer[data-v-1e84a2e1] {
  padding: 24px;
}

@media (max-width: 1024px) {
  .merge-journey .footer[data-v-1e84a2e1] {
    max-width: unset;
  }
}

.merge-journey .footer .content[data-v-1e84a2e1] {
  color: #6f5f5e;
  font-weight: 600;
}

.merge-journey .footer .call a[data-v-1e84a2e1] {
  color: #292828;
  font-size: 18px;
  font-weight: 600;
}

.merge-journey .about-us[data-v-1e84a2e1] {
  font-weight: 600 !important;
}

.merge-journey .icon-wrapper img[data-v-1e84a2e1] {
  width: 16px;
}

.merge-journey[data-v-1e84a2e1] .footer-link-grid {
  gap: 0 8px;
}

.merge-journey[data-v-1e84a2e1] .footer-link-grid .footer-links-section a {
  color: #858282;
}

.merge-journey[data-v-1e84a2e1] .footer-link-grid .footer-links-section div {
  padding: 4px 0;
}

.merge-journey[data-v-1e84a2e1] .footer-link-grid .footer-links-section {
  margin-bottom: 16px;
}

.merge-journey[data-v-1e84a2e1] .footer-link-grid .footer-links-section .bold {
  color: #6f5f5e;
  font-weight: 600;
  font-size: 14px;
}

.merge-journey .footer-bottom[data-v-1e84a2e1] {
  padding-top: 0;
}

.merge-journey .footer-bottom .footer-box-2 .footer-box[data-v-1e84a2e1] {
  margin-top: 16px;
}

.merge-journey .divider-light[data-v-1e84a2e1] {
  border-color: #e0e0e0;
}

.merge-journey .copyright[data-v-1e84a2e1] {
  padding: 0;
  padding-top: 16px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #858282;
  font-weight: 600;
}

.merge-journey .social-icon[data-v-1e84a2e1] {
  margin: 0 12px;
  gap: 0 24px;
  width: unset;
  height: unset;
}

.merge-journey .social-icon img[data-v-1e84a2e1] {
  height: 40px;
  width: 40px;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,*::before,*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article,figcaption,figure,footer,header,main,nav,section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,ul,dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,ul ul,ol ul,ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

b,strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,code {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,button,select,textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,input {
  overflow: visible;
}

button,select {
  text-transform: none;
}

button,html [type="button"],[type="reset"],[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],input[type="time"],input[type="datetime-local"],input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size],select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-control.is-valid,.custom-select.is-valid {
  border-color: #28a745;
}

.form-control.is-valid:focus,.custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-control.is-invalid,.custom-select.is-invalid {
  border-color: #dc3545;
}

.form-control.is-invalid:focus,.custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group,  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover,.btn:focus {
  text-decoration: none;
}

.btn:focus,.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.btn.disabled,.btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,.btn-primary.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-primary.disabled,.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,.btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-secondary.disabled,.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active,.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,.btn-info.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-info.disabled,.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active,.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active:focus,.show>.btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,.btn-warning.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-warning.disabled,.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active,.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,.btn-light.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-light.disabled,.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active,.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active:focus,.show>.btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,.btn-dark.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-dark.disabled,.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active,.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-info.disabled,.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-light.disabled,.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active,.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link:disabled,.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block,input[type="reset"].btn-block,input[type="button"].btn-block {
  width: 100%;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.list-group-item:hover,.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con-webfont.407d448.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_bol-webfont.23e3999.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: 'krungsri_condensed_med';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
}

html,body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'krungsri_condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #705f5f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767.98px) {
  body {
    background-size: auto;
  }
}

body h1 {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 30px;
}

body h2 {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 21px;
}

body h3 {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 20px;
}

body h4 {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 374.98px) {
  body h2 {
    font-size: 18px;
  }

  body h4 {
    font-size: 14px;
  }
}

body b {
  font-family: 'krungsri_condensed_med';
}

body p {
  line-height: 1.7;
  font-weight: normal;
}

header {
  width: 100%;
  max-width: 1024px;
  margin: 8px auto;
}

header .ribbon-ka-75-logo {
  width: 100%;
  height: 64px;
  position: absolute;
}

header .header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  align-items: center;
}

header .header-wrapper-desktop {
  padding-bottom: 20px;
}

header .router-link-section-75-logo {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  z-index: 15;
}

@media (max-width: 320px) {
  header .router-link-section-75-logo {
    margin-right: 16px;
  }
}

header .router-link-section-75-logo img {
  max-width: 142.29px;
  width: 100%;
}

header .router-link-section-75-logo .disabled {
  pointer-events: none;
  cursor: default;
}

header .check-status-v2 {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  width: 101px;
  z-index: 10;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.64;
  color: #6e5f5e;
  border-radius: 4px;
  border: solid 2px #ffd400;
  background-color: #ffd400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

header .check-status-v2 span {
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 320px) {
  header .check-status-v2 {
    right: -12px;
    font-size: 12px;
  }
}

header .check-status-v2 .title {
  padding: 0 8px;
  white-space: nowrap;
}

header .header-banner-go-download {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
  padding-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

header .header-banner-go-download img {
  width: 100%;
}

header .header-banner-go-download-close {
  padding-top: 12px;
  grid-column: 5;
  grid-row: 3 / 4;
  padding-right: 8px;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

header .header-title {
  grid-column: 2 / 6;
  grid-row: 4;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.82;
  color: #6e5f5e;
  padding: 0 20px;
  padding-top: 16px;
  margin: 0 auto;
  max-width: 580px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .menu-container-v2 {
  grid-column: 4 / 4;
  grid-row: 1 / 2;
  z-index: 10;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 8px;
}

header .menu-container-v2 .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  margin-top: 0;
  float: right;
  cursor: pointer;
}

header .menu-container-v2 .menu .title {
  padding: 0 8px;
}

header .menu-item-list-v2 {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: 56px 20px 0;
  -webkit-box-shadow: 0 20px 20px 0 rgba(194,194,194,0.2);
  box-shadow: 0 20px 20px 0 rgba(194,194,194,0.2);
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

header .menu-item-list-v2.isActive {
  opacity: 1;
  visibility: visible;
}

header .menu-item-list-v2 .menu-item {
  text-decoration: none;
  display: block;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  color: #6e5f5e;
  line-height: 40px;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

header .menu-item-list-v2 .menu-item:last-child {
  border-bottom: none;
}

header .menu-item-list-v2 .menu-item:hover {
  color: #ccaa00;
}

header .menu-item-list-v2 .menu-item:last-of-type {
  border-bottom-width: 0px;
}

header .icon-hamberger-v2 {
  width: 23px;
  height: 23px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgb3BhY2l0eT0iMC4wMSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTBIMjRWMTJIOFYxMFpNOCAxNUgyNFYxN0g4VjE1Wk0yMCAyMEg4VjIySDIwVjIwWiIgZmlsbD0iIzU5NEM0QiIvPgo8L3N2Zz4K) no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

header .icon-hamberger-v2.isActive {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgb3BhY2l0eT0iMC4wMSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTBIMjRWMTJIOFYxMFpNOCAxNUgyNFYxN0g4VjE1Wk0yMCAyMEg4VjIySDIwVjIwWiIgZmlsbD0iIzU5NEM0QiIvPgo8L3N2Zz4K) no-repeat;
  background-position: center;
}

header .icon-hamberger {
  width: 23px;
  height: 23px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgb3BhY2l0eT0iMC4wMSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTBIMjRWMTJIOFYxMFpNOCAxNUgyNFYxN0g4VjE1Wk0yMCAyMEg4VjIySDIwVjIwWiIgZmlsbD0iIzU5NEM0QiIvPgo8L3N2Zz4K) no-repeat;
}

header .icon-hamberger.isActive {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgb3BhY2l0eT0iMC4wMSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTBIMjRWMTJIOFYxMFpNOCAxNUgyNFYxN0g4VjE1Wk0yMCAyMEg4VjIySDIwVjIwWiIgZmlsbD0iIzU5NEM0QiIvPgo8L3N2Zz4K) no-repeat;
}

header .icon-check-status {
  width: 23px;
  height: 23px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM1OTRDNEIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEzIDVhMiAyIDAgMCAxIDIgMnYzaDFhMSAxIDAgMCAxIDEgMXY2YTEgMSAwIDAgMS0xIDFIOGExIDEgMCAwIDEtMS0xdi02YTEgMSAwIDAgMSAxLTFoMVY3YTIgMiAwIDAgMSAyLTJoMnptMCAxaC0yYTEgMSAwIDAgMC0uOTkzLjg4M0wxMCA3djNoNFY3YTEgMSAwIDAgMC0uODgzLS45OTNMMTMgNnoiLz4KPC9zdmc+Cg==) no-repeat;
}

.overlay[data-v-f703fff4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8000;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.overlay-container[data-v-f703fff4] {
  position: relative;
}

.overlay-close[data-v-f703fff4] {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  color: #fff;
  padding: 16px 16px;
}

.overlay-close[data-v-f703fff4]:before,.overlay-close[data-v-f703fff4]:after {
  position: absolute;
  left: 21px;
  top: 14px;
  content: ' ';
  height: 18px;
  width: 1px;
  background-color: #444444;
}

.overlay-close[data-v-f703fff4]:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.overlay-close[data-v-f703fff4]:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

[data-v-d39d8eee]:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*[data-v-d39d8eee],*[data-v-d39d8eee]::before,*[data-v-d39d8eee]::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html[data-v-d39d8eee] {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article[data-v-d39d8eee],figcaption[data-v-d39d8eee],figure[data-v-d39d8eee],footer[data-v-d39d8eee],header[data-v-d39d8eee],main[data-v-d39d8eee],nav[data-v-d39d8eee],section[data-v-d39d8eee] {
  display: block;
}

body[data-v-d39d8eee] {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"][data-v-d39d8eee]:focus {
  outline: 0 !important;
}

hr[data-v-d39d8eee] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1[data-v-d39d8eee],h2[data-v-d39d8eee],h3[data-v-d39d8eee],h4[data-v-d39d8eee],h5[data-v-d39d8eee],h6[data-v-d39d8eee] {
  margin-top: 0;
  margin-bottom: .5rem;
}

p[data-v-d39d8eee] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol[data-v-d39d8eee],ul[data-v-d39d8eee],dl[data-v-d39d8eee] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol[data-v-d39d8eee],ul ul[data-v-d39d8eee],ol ul[data-v-d39d8eee],ul ol[data-v-d39d8eee] {
  margin-bottom: 0;
}

dt[data-v-d39d8eee] {
  font-weight: 700;
}

dd[data-v-d39d8eee] {
  margin-bottom: .5rem;
  margin-left: 0;
}

b[data-v-d39d8eee],strong[data-v-d39d8eee] {
  font-weight: bolder;
}

small[data-v-d39d8eee] {
  font-size: 80%;
}

sub[data-v-d39d8eee] {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub[data-v-d39d8eee] {
  bottom: -.25em;
}

a[data-v-d39d8eee] {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a[data-v-d39d8eee]:hover {
  color: #0056b3;
  text-decoration: underline;
}

a[data-v-d39d8eee]:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a[data-v-d39d8eee]:not([href]):not([tabindex]):hover,a[data-v-d39d8eee]:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a[data-v-d39d8eee]:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre[data-v-d39d8eee],code[data-v-d39d8eee] {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre[data-v-d39d8eee] {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure[data-v-d39d8eee] {
  margin: 0 0 1rem;
}

img[data-v-d39d8eee] {
  vertical-align: middle;
  border-style: none;
}

svg[data-v-d39d8eee] {
  overflow: hidden;
  vertical-align: middle;
}

table[data-v-d39d8eee] {
  border-collapse: collapse;
}

caption[data-v-d39d8eee] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th[data-v-d39d8eee] {
  text-align: inherit;
}

label[data-v-d39d8eee] {
  display: inline-block;
  margin-bottom: .5rem;
}

button[data-v-d39d8eee] {
  border-radius: 0;
}

button[data-v-d39d8eee]:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input[data-v-d39d8eee],button[data-v-d39d8eee],select[data-v-d39d8eee],textarea[data-v-d39d8eee] {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button[data-v-d39d8eee],input[data-v-d39d8eee] {
  overflow: visible;
}

button[data-v-d39d8eee],select[data-v-d39d8eee] {
  text-transform: none;
}

button[data-v-d39d8eee],html [type="button"][data-v-d39d8eee],[type="reset"][data-v-d39d8eee],[type="submit"][data-v-d39d8eee] {
  -webkit-appearance: button;
}

button[data-v-d39d8eee]::-moz-focus-inner,[type="button"][data-v-d39d8eee]::-moz-focus-inner,[type="reset"][data-v-d39d8eee]::-moz-focus-inner,[type="submit"][data-v-d39d8eee]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"][data-v-d39d8eee],input[type="checkbox"][data-v-d39d8eee] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"][data-v-d39d8eee],input[type="time"][data-v-d39d8eee],input[type="datetime-local"][data-v-d39d8eee],input[type="month"][data-v-d39d8eee] {
  -webkit-appearance: listbox;
}

textarea[data-v-d39d8eee] {
  overflow: auto;
  resize: vertical;
}

progress[data-v-d39d8eee] {
  vertical-align: baseline;
}

[type="number"][data-v-d39d8eee]::-webkit-inner-spin-button,[type="number"][data-v-d39d8eee]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"][data-v-d39d8eee] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"][data-v-d39d8eee]::-webkit-search-cancel-button,[type="search"][data-v-d39d8eee]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[data-v-d39d8eee]::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary[data-v-d39d8eee] {
  display: list-item;
  cursor: pointer;
}

template[data-v-d39d8eee] {
  display: none;
}

[hidden][data-v-d39d8eee] {
  display: none !important;
}

.form-control[data-v-d39d8eee] {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control[data-v-d39d8eee] {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[data-v-d39d8eee]::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[data-v-d39d8eee]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.form-control[data-v-d39d8eee]::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-d39d8eee]::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-d39d8eee]:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-d39d8eee]::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-d39d8eee]::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-d39d8eee]:disabled,.form-control[readonly][data-v-d39d8eee] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control[data-v-d39d8eee]:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-range[data-v-d39d8eee] {
  display: block;
  width: 100%;
}

.col-form-label[data-v-d39d8eee] {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg[data-v-d39d8eee] {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm[data-v-d39d8eee] {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-sm[data-v-d39d8eee] {
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg[data-v-d39d8eee] {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size][data-v-d39d8eee],select.form-control[multiple][data-v-d39d8eee] {
  height: auto;
}

textarea.form-control[data-v-d39d8eee] {
  height: auto;
}

.form-group[data-v-d39d8eee] {
  margin-bottom: 1rem;
}

.form-text[data-v-d39d8eee] {
  display: block;
  margin-top: .25rem;
}

.form-row[data-v-d39d8eee] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col[data-v-d39d8eee],.form-row>[class*="col-"][data-v-d39d8eee] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check[data-v-d39d8eee] {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input[data-v-d39d8eee] {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label[data-v-d39d8eee] {
  color: #6c757d;
}

.form-check-label[data-v-d39d8eee] {
  margin-bottom: 0;
}

.form-check-inline[data-v-d39d8eee] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input[data-v-d39d8eee] {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-control.is-valid[data-v-d39d8eee],.custom-select.is-valid[data-v-d39d8eee] {
  border-color: #28a745;
}

.form-control.is-valid[data-v-d39d8eee]:focus,.custom-select.is-valid[data-v-d39d8eee]:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-check-input.is-valid ~ .form-check-label[data-v-d39d8eee] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-d39d8eee] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-d39d8eee]::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid:checked ~ .custom-control-label[data-v-d39d8eee]::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label[data-v-d39d8eee]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-control.is-invalid[data-v-d39d8eee],.custom-select.is-invalid[data-v-d39d8eee] {
  border-color: #dc3545;
}

.form-control.is-invalid[data-v-d39d8eee]:focus,.custom-select.is-invalid[data-v-d39d8eee]:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-check-input.is-invalid ~ .form-check-label[data-v-d39d8eee] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-d39d8eee] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-d39d8eee]::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label[data-v-d39d8eee]::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label[data-v-d39d8eee]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-inline[data-v-d39d8eee] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check[data-v-d39d8eee] {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label[data-v-d39d8eee] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group[data-v-d39d8eee] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control[data-v-d39d8eee] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group[data-v-d39d8eee],  .form-inline .custom-select[data-v-d39d8eee] {
    width: auto;
  }

  .form-inline .form-check[data-v-d39d8eee] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input[data-v-d39d8eee] {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control[data-v-d39d8eee] {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label[data-v-d39d8eee] {
    margin-bottom: 0;
  }
}

.btn[data-v-d39d8eee] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn[data-v-d39d8eee] {
    -webkit-transition: none;
    transition: none;
  }
}

.btn[data-v-d39d8eee]:hover,.btn[data-v-d39d8eee]:focus {
  text-decoration: none;
}

.btn[data-v-d39d8eee]:focus,.btn.focus[data-v-d39d8eee] {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.btn.disabled[data-v-d39d8eee],.btn[data-v-d39d8eee]:disabled {
  opacity: .65;
}

.btn[data-v-d39d8eee]:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled[data-v-d39d8eee] {
  pointer-events: none;
}

.btn-primary[data-v-d39d8eee] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary[data-v-d39d8eee]:focus,.btn-primary.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-primary.disabled[data-v-d39d8eee],.btn-primary[data-v-d39d8eee]:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-primary.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-primary.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-secondary[data-v-d39d8eee] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary[data-v-d39d8eee]:focus,.btn-secondary.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-secondary.disabled[data-v-d39d8eee],.btn-secondary[data-v-d39d8eee]:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-secondary.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-secondary.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-info[data-v-d39d8eee] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info[data-v-d39d8eee]:focus,.btn-info.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-info.disabled[data-v-d39d8eee],.btn-info[data-v-d39d8eee]:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-info.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-info.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-warning[data-v-d39d8eee] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-d39d8eee]:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning[data-v-d39d8eee]:focus,.btn-warning.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-warning.disabled[data-v-d39d8eee],.btn-warning[data-v-d39d8eee]:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-warning.dropdown-toggle[data-v-d39d8eee] {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-warning.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-light[data-v-d39d8eee] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-d39d8eee]:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light[data-v-d39d8eee]:focus,.btn-light.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-light.disabled[data-v-d39d8eee],.btn-light[data-v-d39d8eee]:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-light.dropdown-toggle[data-v-d39d8eee] {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-light.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-dark[data-v-d39d8eee] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark[data-v-d39d8eee]:focus,.btn-dark.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-dark.disabled[data-v-d39d8eee],.btn-dark[data-v-d39d8eee]:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-dark.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-dark.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-primary[data-v-d39d8eee] {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-d39d8eee]:focus,.btn-outline-primary.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-primary.disabled[data-v-d39d8eee],.btn-outline-primary[data-v-d39d8eee]:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-primary.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-primary.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-secondary[data-v-d39d8eee] {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-d39d8eee]:focus,.btn-outline-secondary.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-secondary.disabled[data-v-d39d8eee],.btn-outline-secondary[data-v-d39d8eee]:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-secondary.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-secondary.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-info[data-v-d39d8eee] {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-d39d8eee]:focus,.btn-outline-info.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-info.disabled[data-v-d39d8eee],.btn-outline-info[data-v-d39d8eee]:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-info.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-info.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-warning[data-v-d39d8eee] {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-d39d8eee]:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-d39d8eee]:focus,.btn-outline-warning.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-warning.disabled[data-v-d39d8eee],.btn-outline-warning[data-v-d39d8eee]:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-warning.dropdown-toggle[data-v-d39d8eee] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-warning.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-light[data-v-d39d8eee] {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-d39d8eee]:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-d39d8eee]:focus,.btn-outline-light.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-light.disabled[data-v-d39d8eee],.btn-outline-light[data-v-d39d8eee]:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-light.dropdown-toggle[data-v-d39d8eee] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-light.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-dark[data-v-d39d8eee] {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark[data-v-d39d8eee]:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-d39d8eee]:focus,.btn-outline-dark.focus[data-v-d39d8eee] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-dark.disabled[data-v-d39d8eee],.btn-outline-dark[data-v-d39d8eee]:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark[data-v-d39d8eee]:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-d39d8eee],.show>.btn-outline-dark.dropdown-toggle[data-v-d39d8eee] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-d39d8eee]:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-d39d8eee]:focus,.show>.btn-outline-dark.dropdown-toggle[data-v-d39d8eee]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-link[data-v-d39d8eee] {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link[data-v-d39d8eee]:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link[data-v-d39d8eee]:focus,.btn-link.focus[data-v-d39d8eee] {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link[data-v-d39d8eee]:disabled,.btn-link.disabled[data-v-d39d8eee] {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg[data-v-d39d8eee] {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm[data-v-d39d8eee] {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block[data-v-d39d8eee] {
  display: block;
  width: 100%;
}

.btn-block+.btn-block[data-v-d39d8eee] {
  margin-top: .5rem;
}

input[type="submit"].btn-block[data-v-d39d8eee],input[type="reset"].btn-block[data-v-d39d8eee],input[type="button"].btn-block[data-v-d39d8eee] {
  width: 100%;
}

.list-group[data-v-d39d8eee] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action[data-v-d39d8eee] {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-action[data-v-d39d8eee]:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action[data-v-d39d8eee]:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item[data-v-d39d8eee] {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
}

.list-group-item[data-v-d39d8eee]:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item[data-v-d39d8eee]:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.list-group-item[data-v-d39d8eee]:hover,.list-group-item[data-v-d39d8eee]:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled[data-v-d39d8eee],.list-group-item[data-v-d39d8eee]:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active[data-v-d39d8eee] {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-primary[data-v-d39d8eee] {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-primary.list-group-item-action[data-v-d39d8eee]:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary[data-v-d39d8eee] {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-secondary.list-group-item-action[data-v-d39d8eee]:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-info[data-v-d39d8eee] {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-info.list-group-item-action[data-v-d39d8eee]:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning[data-v-d39d8eee] {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-warning.list-group-item-action[data-v-d39d8eee]:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-light[data-v-d39d8eee] {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-light.list-group-item-action[data-v-d39d8eee]:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark[data-v-d39d8eee] {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action[data-v-d39d8eee]:hover,.list-group-item-dark.list-group-item-action[data-v-d39d8eee]:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active[data-v-d39d8eee] {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con-webfont.407d448.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_bol-webfont.23e3999.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: 'krungsri_condensed_med';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
}

html[data-v-d39d8eee],body[data-v-d39d8eee] {
  width: 100%;
  height: 100%;
}

body[data-v-d39d8eee] {
  font-family: 'krungsri_condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #705f5f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767.98px) {
  body[data-v-d39d8eee] {
    background-size: auto;
  }
}

body h1[data-v-d39d8eee] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 30px;
}

body h2[data-v-d39d8eee] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 21px;
}

body h3[data-v-d39d8eee] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 20px;
}

body h4[data-v-d39d8eee] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 374.98px) {
  body h2[data-v-d39d8eee] {
    font-size: 18px;
  }

  body h4[data-v-d39d8eee] {
    font-size: 14px;
  }
}

body b[data-v-d39d8eee] {
  font-family: 'krungsri_condensed_med';
}

body p[data-v-d39d8eee] {
  line-height: 1.7;
  font-weight: normal;
}

.out-of-service[data-v-d39d8eee] {
  display: table;
  margin: 30px auto auto auto;
  text-align: center;
  color: #5a5a5a;
}

.out-of-service img[data-v-d39d8eee] {
  height: 220px;
  width: 220px;
}

.out-of-service .title[data-v-d39d8eee] {
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.out-of-service .krungsri-auto-promptstart-text[data-v-d39d8eee] {
  font-size: 22px;
  font-weight: 500;
  line-height: 37px;
  display: inline-grid;
}

@media (min-width: 600px) {
  .out-of-service .krungsri-auto-promptstart-text[data-v-d39d8eee] {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
  }
}

.out-of-service .krungsri-auto-promptstart-text .text[data-v-d39d8eee] {
  font-weight: normal;
}

@media (max-width: 599px) {
  .out-of-service .krungsri-auto-promptstart-text .text[data-v-d39d8eee] {
    font-size: 14px;
    line-height: 28px;
  }
}

.out-of-service .message[data-v-d39d8eee] {
  font-size: 14px;
  line-height: 28px;
}

@media (min-width: 600px) {
  .out-of-service .message[data-v-d39d8eee] {
    font-size: 16px;
  }
}

.out-of-service .mb-24[data-v-d39d8eee] {
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .svg-container svg[data-v-00df5ff2] {
    width: 260px;
    height: 260px;
  }
}

@media (min-width: 768px) {
  .svg-container svg[data-v-00df5ff2] {
    width: 548px;
    height: 548px;
  }
}

.service-down[data-v-34da662a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

.service-down img[data-v-34da662a] {
  width: auto;
}

@media (max-width: 767px) {
  .service-down img[data-v-34da662a] {
    width: 100%;
  }
}

[data-v-ceb6ffd6]:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*[data-v-ceb6ffd6],*[data-v-ceb6ffd6]::before,*[data-v-ceb6ffd6]::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html[data-v-ceb6ffd6] {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article[data-v-ceb6ffd6],figcaption[data-v-ceb6ffd6],figure[data-v-ceb6ffd6],footer[data-v-ceb6ffd6],header[data-v-ceb6ffd6],main[data-v-ceb6ffd6],nav[data-v-ceb6ffd6],section[data-v-ceb6ffd6] {
  display: block;
}

body[data-v-ceb6ffd6] {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"][data-v-ceb6ffd6]:focus {
  outline: 0 !important;
}

hr[data-v-ceb6ffd6] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1[data-v-ceb6ffd6],h2[data-v-ceb6ffd6],h3[data-v-ceb6ffd6],h4[data-v-ceb6ffd6],h5[data-v-ceb6ffd6],h6[data-v-ceb6ffd6] {
  margin-top: 0;
  margin-bottom: .5rem;
}

p[data-v-ceb6ffd6] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol[data-v-ceb6ffd6],ul[data-v-ceb6ffd6],dl[data-v-ceb6ffd6] {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol[data-v-ceb6ffd6],ul ul[data-v-ceb6ffd6],ol ul[data-v-ceb6ffd6],ul ol[data-v-ceb6ffd6] {
  margin-bottom: 0;
}

dt[data-v-ceb6ffd6] {
  font-weight: 700;
}

dd[data-v-ceb6ffd6] {
  margin-bottom: .5rem;
  margin-left: 0;
}

b[data-v-ceb6ffd6],strong[data-v-ceb6ffd6] {
  font-weight: bolder;
}

small[data-v-ceb6ffd6] {
  font-size: 80%;
}

sub[data-v-ceb6ffd6] {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub[data-v-ceb6ffd6] {
  bottom: -.25em;
}

a[data-v-ceb6ffd6] {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a[data-v-ceb6ffd6]:hover {
  color: #0056b3;
  text-decoration: underline;
}

a[data-v-ceb6ffd6]:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a[data-v-ceb6ffd6]:not([href]):not([tabindex]):hover,a[data-v-ceb6ffd6]:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a[data-v-ceb6ffd6]:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre[data-v-ceb6ffd6],code[data-v-ceb6ffd6] {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre[data-v-ceb6ffd6] {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure[data-v-ceb6ffd6] {
  margin: 0 0 1rem;
}

img[data-v-ceb6ffd6] {
  vertical-align: middle;
  border-style: none;
}

svg[data-v-ceb6ffd6] {
  overflow: hidden;
  vertical-align: middle;
}

table[data-v-ceb6ffd6] {
  border-collapse: collapse;
}

caption[data-v-ceb6ffd6] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th[data-v-ceb6ffd6] {
  text-align: inherit;
}

label[data-v-ceb6ffd6] {
  display: inline-block;
  margin-bottom: .5rem;
}

button[data-v-ceb6ffd6] {
  border-radius: 0;
}

button[data-v-ceb6ffd6]:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input[data-v-ceb6ffd6],button[data-v-ceb6ffd6],select[data-v-ceb6ffd6],textarea[data-v-ceb6ffd6] {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button[data-v-ceb6ffd6],input[data-v-ceb6ffd6] {
  overflow: visible;
}

button[data-v-ceb6ffd6],select[data-v-ceb6ffd6] {
  text-transform: none;
}

button[data-v-ceb6ffd6],html [type="button"][data-v-ceb6ffd6],[type="reset"][data-v-ceb6ffd6],[type="submit"][data-v-ceb6ffd6] {
  -webkit-appearance: button;
}

button[data-v-ceb6ffd6]::-moz-focus-inner,[type="button"][data-v-ceb6ffd6]::-moz-focus-inner,[type="reset"][data-v-ceb6ffd6]::-moz-focus-inner,[type="submit"][data-v-ceb6ffd6]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"][data-v-ceb6ffd6],input[type="checkbox"][data-v-ceb6ffd6] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"][data-v-ceb6ffd6],input[type="time"][data-v-ceb6ffd6],input[type="datetime-local"][data-v-ceb6ffd6],input[type="month"][data-v-ceb6ffd6] {
  -webkit-appearance: listbox;
}

textarea[data-v-ceb6ffd6] {
  overflow: auto;
  resize: vertical;
}

progress[data-v-ceb6ffd6] {
  vertical-align: baseline;
}

[type="number"][data-v-ceb6ffd6]::-webkit-inner-spin-button,[type="number"][data-v-ceb6ffd6]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"][data-v-ceb6ffd6] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"][data-v-ceb6ffd6]::-webkit-search-cancel-button,[type="search"][data-v-ceb6ffd6]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[data-v-ceb6ffd6]::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary[data-v-ceb6ffd6] {
  display: list-item;
  cursor: pointer;
}

template[data-v-ceb6ffd6] {
  display: none;
}

[hidden][data-v-ceb6ffd6] {
  display: none !important;
}

.form-control[data-v-ceb6ffd6] {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control[data-v-ceb6ffd6] {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[data-v-ceb6ffd6]::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[data-v-ceb6ffd6]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.form-control[data-v-ceb6ffd6]::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-ceb6ffd6]::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-ceb6ffd6]:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-ceb6ffd6]::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-ceb6ffd6]::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control[data-v-ceb6ffd6]:disabled,.form-control[readonly][data-v-ceb6ffd6] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control[data-v-ceb6ffd6]:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-range[data-v-ceb6ffd6] {
  display: block;
  width: 100%;
}

.col-form-label[data-v-ceb6ffd6] {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg[data-v-ceb6ffd6] {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm[data-v-ceb6ffd6] {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-sm[data-v-ceb6ffd6] {
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg[data-v-ceb6ffd6] {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size][data-v-ceb6ffd6],select.form-control[multiple][data-v-ceb6ffd6] {
  height: auto;
}

textarea.form-control[data-v-ceb6ffd6] {
  height: auto;
}

.form-group[data-v-ceb6ffd6] {
  margin-bottom: 1rem;
}

.form-text[data-v-ceb6ffd6] {
  display: block;
  margin-top: .25rem;
}

.form-row[data-v-ceb6ffd6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col[data-v-ceb6ffd6],.form-row>[class*="col-"][data-v-ceb6ffd6] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check[data-v-ceb6ffd6] {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input[data-v-ceb6ffd6] {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label[data-v-ceb6ffd6] {
  color: #6c757d;
}

.form-check-label[data-v-ceb6ffd6] {
  margin-bottom: 0;
}

.form-check-inline[data-v-ceb6ffd6] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input[data-v-ceb6ffd6] {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-control.is-valid[data-v-ceb6ffd6],.custom-select.is-valid[data-v-ceb6ffd6] {
  border-color: #28a745;
}

.form-control.is-valid[data-v-ceb6ffd6]:focus,.custom-select.is-valid[data-v-ceb6ffd6]:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-check-input.is-valid ~ .form-check-label[data-v-ceb6ffd6] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-ceb6ffd6] {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label[data-v-ceb6ffd6]::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid:checked ~ .custom-control-label[data-v-ceb6ffd6]::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label[data-v-ceb6ffd6]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(40,167,69,0.25);
}

.form-control.is-invalid[data-v-ceb6ffd6],.custom-select.is-invalid[data-v-ceb6ffd6] {
  border-color: #dc3545;
}

.form-control.is-invalid[data-v-ceb6ffd6]:focus,.custom-select.is-invalid[data-v-ceb6ffd6]:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-check-input.is-invalid ~ .form-check-label[data-v-ceb6ffd6] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-ceb6ffd6] {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label[data-v-ceb6ffd6]::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label[data-v-ceb6ffd6]::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label[data-v-ceb6ffd6]::before {
  -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-inline[data-v-ceb6ffd6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check[data-v-ceb6ffd6] {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label[data-v-ceb6ffd6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group[data-v-ceb6ffd6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control[data-v-ceb6ffd6] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group[data-v-ceb6ffd6],  .form-inline .custom-select[data-v-ceb6ffd6] {
    width: auto;
  }

  .form-inline .form-check[data-v-ceb6ffd6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input[data-v-ceb6ffd6] {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control[data-v-ceb6ffd6] {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label[data-v-ceb6ffd6] {
    margin-bottom: 0;
  }
}

.btn[data-v-ceb6ffd6] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn[data-v-ceb6ffd6] {
    -webkit-transition: none;
    transition: none;
  }
}

.btn[data-v-ceb6ffd6]:hover,.btn[data-v-ceb6ffd6]:focus {
  text-decoration: none;
}

.btn[data-v-ceb6ffd6]:focus,.btn.focus[data-v-ceb6ffd6] {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.btn.disabled[data-v-ceb6ffd6],.btn[data-v-ceb6ffd6]:disabled {
  opacity: .65;
}

.btn[data-v-ceb6ffd6]:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled[data-v-ceb6ffd6] {
  pointer-events: none;
}

.btn-primary[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary[data-v-ceb6ffd6]:focus,.btn-primary.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-primary.disabled[data-v-ceb6ffd6],.btn-primary[data-v-ceb6ffd6]:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-primary.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-primary.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-secondary[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary[data-v-ceb6ffd6]:focus,.btn-secondary.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-secondary.disabled[data-v-ceb6ffd6],.btn-secondary[data-v-ceb6ffd6]:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-secondary.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-secondary.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-info[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info[data-v-ceb6ffd6]:focus,.btn-info.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-info.disabled[data-v-ceb6ffd6],.btn-info[data-v-ceb6ffd6]:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-info.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-info.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-warning[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-ceb6ffd6]:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning[data-v-ceb6ffd6]:focus,.btn-warning.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-warning.disabled[data-v-ceb6ffd6],.btn-warning[data-v-ceb6ffd6]:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-warning.dropdown-toggle[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-warning.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-light[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-ceb6ffd6]:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light[data-v-ceb6ffd6]:focus,.btn-light.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-light.disabled[data-v-ceb6ffd6],.btn-light[data-v-ceb6ffd6]:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-light.dropdown-toggle[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-light.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-dark[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark[data-v-ceb6ffd6]:focus,.btn-dark.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-dark.disabled[data-v-ceb6ffd6],.btn-dark[data-v-ceb6ffd6]:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-dark.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-dark.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-primary[data-v-ceb6ffd6] {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-ceb6ffd6]:focus,.btn-outline-primary.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-primary.disabled[data-v-ceb6ffd6],.btn-outline-primary[data-v-ceb6ffd6]:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-primary.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-primary.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-secondary[data-v-ceb6ffd6] {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-ceb6ffd6]:focus,.btn-outline-secondary.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-secondary.disabled[data-v-ceb6ffd6],.btn-outline-secondary[data-v-ceb6ffd6]:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-secondary.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-secondary.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-info[data-v-ceb6ffd6] {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-ceb6ffd6]:focus,.btn-outline-info.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-info.disabled[data-v-ceb6ffd6],.btn-outline-info[data-v-ceb6ffd6]:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-info.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-info.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-warning[data-v-ceb6ffd6] {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-ceb6ffd6]:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-ceb6ffd6]:focus,.btn-outline-warning.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-warning.disabled[data-v-ceb6ffd6],.btn-outline-warning[data-v-ceb6ffd6]:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-warning.dropdown-toggle[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-warning.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-light[data-v-ceb6ffd6] {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-ceb6ffd6]:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-ceb6ffd6]:focus,.btn-outline-light.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-light.disabled[data-v-ceb6ffd6],.btn-outline-light[data-v-ceb6ffd6]:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-light.dropdown-toggle[data-v-ceb6ffd6] {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-light.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-dark[data-v-ceb6ffd6] {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark[data-v-ceb6ffd6]:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-ceb6ffd6]:focus,.btn-outline-dark.focus[data-v-ceb6ffd6] {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-dark.disabled[data-v-ceb6ffd6],.btn-outline-dark[data-v-ceb6ffd6]:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-ceb6ffd6],.show>.btn-outline-dark.dropdown-toggle[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark[data-v-ceb6ffd6]:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active[data-v-ceb6ffd6]:focus,.show>.btn-outline-dark.dropdown-toggle[data-v-ceb6ffd6]:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-link[data-v-ceb6ffd6] {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link[data-v-ceb6ffd6]:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link[data-v-ceb6ffd6]:focus,.btn-link.focus[data-v-ceb6ffd6] {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link[data-v-ceb6ffd6]:disabled,.btn-link.disabled[data-v-ceb6ffd6] {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg[data-v-ceb6ffd6] {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm[data-v-ceb6ffd6] {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block[data-v-ceb6ffd6] {
  display: block;
  width: 100%;
}

.btn-block+.btn-block[data-v-ceb6ffd6] {
  margin-top: .5rem;
}

input[type="submit"].btn-block[data-v-ceb6ffd6],input[type="reset"].btn-block[data-v-ceb6ffd6],input[type="button"].btn-block[data-v-ceb6ffd6] {
  width: 100%;
}

.list-group[data-v-ceb6ffd6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action[data-v-ceb6ffd6] {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action[data-v-ceb6ffd6]:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item[data-v-ceb6ffd6] {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
}

.list-group-item[data-v-ceb6ffd6]:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item[data-v-ceb6ffd6]:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.list-group-item[data-v-ceb6ffd6]:hover,.list-group-item[data-v-ceb6ffd6]:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled[data-v-ceb6ffd6],.list-group-item[data-v-ceb6ffd6]:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active[data-v-ceb6ffd6] {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-primary[data-v-ceb6ffd6] {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-primary.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary[data-v-ceb6ffd6] {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-secondary.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-info[data-v-ceb6ffd6] {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-info.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning[data-v-ceb6ffd6] {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-warning.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-light[data-v-ceb6ffd6] {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-light.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark[data-v-ceb6ffd6] {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action[data-v-ceb6ffd6]:hover,.list-group-item-dark.list-group-item-action[data-v-ceb6ffd6]:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active[data-v-ceb6ffd6] {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con-webfont.407d448.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: 'krungsri_condensed';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_bol-webfont.23e3999.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: 'krungsri_condensed_med';
  font-display: swap;
  src: url(/static/fonts/krungsri_con_med-webfont.1f52d0f.woff) format("woff");
}

html[data-v-ceb6ffd6],body[data-v-ceb6ffd6] {
  width: 100%;
  height: 100%;
}

body[data-v-ceb6ffd6] {
  font-family: 'krungsri_condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #705f5f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767.98px) {
  body[data-v-ceb6ffd6] {
    background-size: auto;
  }
}

body h1[data-v-ceb6ffd6] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 30px;
}

body h2[data-v-ceb6ffd6] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 21px;
}

body h3[data-v-ceb6ffd6] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 20px;
}

body h4[data-v-ceb6ffd6] {
  color: #6f5f5e;
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 374.98px) {
  body h2[data-v-ceb6ffd6] {
    font-size: 18px;
  }

  body h4[data-v-ceb6ffd6] {
    font-size: 14px;
  }
}

body b[data-v-ceb6ffd6] {
  font-family: 'krungsri_condensed_med';
}

body p[data-v-ceb6ffd6] {
  line-height: 1.7;
  font-weight: normal;
}

#app[data-v-ceb6ffd6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pre-wrapper[data-v-ceb6ffd6] {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 580px;
  background-color: #fff;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.pre-wrapper-deallist[data-v-ceb6ffd6] {
  background-color: #f1efef;
}

.wrapper[data-v-ceb6ffd6] {
  padding: 10px 20px 30px;
  height: 100%;
}

.faq[data-v-ceb6ffd6] {
  padding: 0px;
}

.home-wrapper[data-v-ceb6ffd6] {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

@media (max-width: 320px) {
  .home-wrapper[data-v-ceb6ffd6] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 321px) and (max-width: 767px) {
  .home-wrapper[data-v-ceb6ffd6] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home-wrapper[data-v-ceb6ffd6] {
    max-width: 550px;
  }
}

@media (min-width: 1025px) {
  .home-wrapper[data-v-ceb6ffd6] {
    max-width: 960px;
    padding-bottom: 0px;
  }
}

.home-wrapper-clear[data-v-ceb6ffd6] {
  padding-top: 0;
}

.merge-journey[data-v-ceb6ffd6] {
  padding: 0;
  background-color: #f8f7f7;
  max-width: unset;
}

.clearWrapperPadding[data-v-ceb6ffd6] {
  padding-bottom: 0px;
}

@media (max-width: 767px) {
  .svg-container svg[data-v-ceb6ffd6] {
    width: 260px;
    height: 260px;
  }
}

@media (min-width: 768px) {
  .svg-container svg[data-v-ceb6ffd6] {
    width: 548px;
    height: 548px;
  }
}

.warpper-with-blogs[data-v-ceb6ffd6] {
  padding-bottom: 0;
}

.loading[data-v-ceb6ffd6] {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 99999999;
  background-color: #fff;
}

.loading .wrap-item[data-v-ceb6ffd6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 204px;
}

.loading .wrap-item img.loading-cloud[data-v-ceb6ffd6] {
  height: 190px;
  width: 251px;
}

.loading .wrap-item span[data-v-ceb6ffd6] {
  color: #757575;
  margin-bottom: 8px;
  font-size: 18px;
}

.loading .wrap-item .check[data-v-ceb6ffd6] {
  font-size: 20px;
  color: #444444;
  margin-top: 30px;
  font-weight: 700;
}